<template>
<div class="view-home" v-if="proc === 1">
  <div class="container questions-wrap pt-5" v-if="!loading">
    <div class="question-title">
      <h3 class="text-primary">{{ $t('rules.title') }}</h3>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <div class="question-content">
          {{ $t('rules.not-allowed') }}
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-12 d-flex flex-column align-items-center test-rule">
                <img height="170" src="/images/rule1.svg" alt="">
                <img class="pb-4 pt-4" width="70" src="/images/no.png" alt="">
                <div>{{ $t('rules.rule-1') }}</div>
            </div>
            <div class="col-lg-4 col-md-12 d-flex flex-column align-items-center test-rule">
                <img height="170" src="/images/rule2.svg" alt="">
                <img class="pb-4 pt-4" width="70" src="/images/no.png" alt="">
                <div>{{ $t('rules.rule-2') }}</div>
            </div>
            <div class="col-lg-4 col-md-12 d-flex flex-column align-items-center test-rule">
                <img height="170" src="/images/rule3.svg" alt="">
                <img class="pb-4 pt-4" width="70" src="/images/no.png" alt="">
                <div>{{ $t('rules.rule-3') }}</div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 col-md-12 d-flex flex-row test-rule justify-content-center">
                <img height="284" src="/images/rule4.svg" alt="">
                <img class="mt-auto tick" width="70" src="/images/yes.png" alt="">
            </div>
        </div>
      </div>
    </div>
    <div class="row my-5">
      <div class="mx-auto">
        <a @click="next" class="btn btn-primary">{{ $t('rules.next-button') }}</a>
<!--        <router-link to="/identification" tag="a" class="btn btn-primary">Следующий шаг</router-link>-->
      </div>
    </div>
  </div>
  <Loader v-else/>
</div>
</template>
<script>
import Loader from '../../components/Loader';
export default {
  name: "Index",
  data() {
    return {
      proc: 0,
      test_lang: 'ru',
      loading: true,
    }
  },
  components: {
    Loader
  },
  methods: {
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    },
    next(){
      let quiz = JSON.parse(localStorage.getItem('quiz'));
      let test_lang = localStorage.getItem('test_lang')
      if (test_lang) {
        this.test_lang = test_lang;
      }
      if (quiz.test_language){
        this.$i18n.locale = this.test_lang;
      }
      if (quiz && quiz.proctoring) {
        if (quiz.proctoring == 1) {
          this.$router.push({ name: 'identification' })
        } else {
          this.$router.push({ name: 'about' })
        }
      } else {
        this.$router.push({ name: 'about' })
      }
    }
  },
  mounted() {
    this.loading = true
      this.$http.get(API_ROOT + '/api/quiz/' + this.$route.params.token).then((res) => {
          if (res.body) {
            localStorage.removeItem('saved-answers');
            localStorage.setItem('quiz-token', this.$route.params.token);
            localStorage.setItem('test_lang', res.body.test_language);
            localStorage.setItem('user_quiz_id',res.body.user_quiz_id)
            localStorage.setItem('timer', res.body.remaining_time );
            this.$i18n.locale = res.body.test_language;
            if (res.body.data) {
              const quizData = res.body.data
              quizData.test_language=res.body.test_language
              quizData.questions.forEach(question => {
                if (question.answer_type_id === 6) {
                  const arr = question.answers[0][`text_${quizData.test_language}`].split('{word}')
                  let newStr = ''
                  for (let i = 0; i < arr.length; i++) {
                    newStr += arr[i]
                    if (i < arr.length - 1) {
                      newStr += `<input type="text" data-model="${i}" />`
                    }
                  }
                  question.answers[0][`text_${quizData.test_language}`] = newStr
                }
                if (question.answer_type_id === 7) {
                  const arr = question[`text_${quizData.test_language}`].split('{word}')
                  const selects = []
                  let text = ''
                  for (let i = 0; i < question.answers.length; i++) {
                    let select = `<select data-model="${i}" class="custom-answer-select">`
                    question.answers[i].map(option => {
                      select += '<option>' + option[`text_${quizData.test_language}`] + '</option>'
                    })
                    select += '</select>'
                    selects.push(select)
                  }
                  for (let i = 0; i < arr.length; i++) {
                    text += arr[i]
                    if (i < arr.length - 1) {
                      text += selects[i]
                    }
                  }
                  question[`text_${quizData.test_language}`] = text
                }
              });
              quiz=res.body.data
              quiz.remaining_time=res.body.remaining_time
              quiz.questions = this.shuffleArray(quiz.questions)
              localStorage.setItem('quiz', JSON.stringify(quiz));
            }
          }
          let quiz = JSON.parse(localStorage.getItem('quiz'));
          localStorage.removeItem('timerCollaboration')
          localStorage.removeItem('timerCriticalThinking')
          localStorage.removeItem('timerСommunications')
          localStorage.removeItem('timerСreativity')
          localStorage.removeItem('timerSelfEsteem')
          localStorage.removeItem('quiz-params-15-17')
          localStorage.removeItem('timerComplexExplanations')
          localStorage.removeItem('timerCreativitySubtest1')
          localStorage.removeItem('timerCreativitySubtest2')
          localStorage.removeItem('currentSubtest')
          localStorage.removeItem('timerCreativitySubtest3')
          localStorage.removeItem('timerHiddenForm')
          localStorage.removeItem('timerIncorrectPhrase')
          localStorage.removeItem('timerFunnyStories')
          localStorage.removeItem('timerThomasQuestionnaire')
          localStorage.removeItem('current-test')

          this.loading = false;
          if (quiz && quiz.proctoring === 0) {
            this.proc = 0;
            this.$router.push({name: 'about'})
          }
          if (quiz && quiz.proctoring === 1) {
            this.proc = 1;
          }
    })
    .catch((e) => {
      this.loading = false;
      if (e.status === 404) {
        this.$router.push({ name: 'not-found' })
      }
      // if(e.status >= 500) {
      //   alert('Данные не подгрузились. Возможно, высокая нагрузка на сайт или низкая скорость интернета. Перезагрузите страницу.')
      // }
      console.log(e);
    })
  }
}
</script>
<style>
.test-rule {
    padding: 70px 0;
}
.test-rule div {
    color: #e24c4b;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    width: 220px;
}
.tick {
    margin-left: -55px;
    margin-bottom: -10px;
}
</style>
